import React from "react"
//import { FaBeer } from 'react-icons/fa';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, Banner } from '../utils'
import newsImg from '../images/bg/newsBg.jpg'

const NewsPage = () => (
  <Layout>
    <SEO title="News" keywords={[`project`, `team`, `people`]} />
    <PageHeader img={newsImg}>
      <Banner title="News" subtitle="Keeping you all up to date" />
    </PageHeader>

  </Layout>
)

export default NewsPage